@import "https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap";
@import "https://fonts.cdnfonts.com/css/satoshi";
body {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  --body-font: "Satoshi", sans-serif;
  font-family: var(--body-font);
  text-align: center;
  --hex-value-font-weight: unset;
  --underlay-color: #fff;
  --background-color: #e8e8e8;
  background-color: var(--underlay-color);
  --icon-color-filter: initial;
  --body-color: #2e2e2e;
  color: var(--body-color);
  --hex-cell-enabled-inner-border-color: #c9d7de;
  --hex-cell-disabled-inner-border-color: #93a6af;
  --hex-disabled-color: #b0bec5;
  --hex-color: #f5f5f5;
  --hex-bad-color: #d32f2f;
  --hex-good-color: #90ee90;
  --hex-reset-color: #607d8b;
  --hex-hover-color: #c4e1ef;
  --button-background-color: var(--background-color);
  --button-hover-background-color: #90a4ae;
  --modal-background-color: #00000080;
  --info-box-background-color: #d9d9d9;
  --input-background-color: var(--underlay-color);
  --input-border-color: #b0bec5;
  --color-note-blue: #add8e6;
  --color-note-green: #cee5b9;
  --color-note-yellow: #ff9;
  --color-note-orange: #ffb347;
  --color-note-red: #f99;
  --color-note-purple: orchid;
  --color-note-cyan: #008b8b;
  --color-note-magenta: #f7f;
  --color-note-brown: tan;
  --quote-border-color: #90a4ae;
  --quote-background-color: #f5f5f5;
  --quote-text-color: #2e2e2e;
  --quote-symbol-color: #b0bec5;
  --quote-source-color: #607d8b;
  --daily-puzzle-active-color: orchid;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 1.2em;
  display: flex;
  position: absolute;
  overflow-x: hidden;
}

body.dark-theme {
  --underlay-color: #1d1d20;
  --background-color: #36393b;
  --body-color: #e0e0e0;
  --icon-color-filter: invert(100%);
  --hex-cell-enabled-inner-border-color: #5b737e;
  --hex-cell-disabled-inner-border-color: #35414c;
  --hex-disabled-color: #1c2833;
  --hex-color: #455769;
  --hex-bad-color: #cf6679;
  --hex-good-color: #81c784;
  --hex-reset-color: #455a64;
  --hex-hover-color: #90a5af;
  --button-background-color: #1d1d20;
  --button-hover-background-color: #78909c;
  --info-box-background-color: #55575c;
  --input-background-color: var(--underlay-color);
  --input-border-color: #546e7a;
  --color-note-blue: #4169e1;
  --color-note-green: #3cb371;
  --color-note-yellow: #ccac00;
  --color-note-orange: #ff8c00;
  --color-note-red: tomato;
  --color-note-purple: #9932cc;
  --color-note-cyan: #20b2aa;
  --color-note-magenta: #f0f;
  --color-note-brown: #8b4513;
  --quote-border-color: #5b737e;
  --quote-background-color: #1d1d20;
  --quote-text-color: #e0e0e0;
  --quote-symbol-color: #455769;
  --quote-source-color: #90a5af;
  --daily-puzzle-active-color: #9932cc;
}

body.aqua-theme {
  --underlay-color: #d8f0ff;
  --background-color: #b8e0e8;
  background-color: var(--underlay-color);
  --icon-color-filter: initial;
  --body-color: #2a3a4a;
  color: var(--body-color);
  --hex-cell-enabled-inner-border-color: #98b8c2;
  --hex-cell-disabled-inner-border-color: #73939e;
  --hex-disabled-color: #90aeb5;
  --hex-color: #e0f7fa;
  --hex-bad-color: #ff6f61;
  --hex-good-color: #48c9b0;
  --hex-reset-color: #5a7d8a;
  --hex-hover-color: #a4d5e0;
  --button-background-color: var(--background-color);
  --button-hover-background-color: #81c8d0;
  --modal-background-color: #00000080;
  --info-box-background-color: #98b8c2;
  --input-background-color: var(--underlay-color);
  --input-border-color: #90aeb5;
  --color-note-blue: #76c7c0;
  --color-note-green: #b2d8c7;
  --color-note-yellow: #f7d488;
  --color-note-orange: #ff9e7d;
  --color-note-red: #ff6f61;
  --color-note-purple: #9a67d4;
  --color-note-cyan: #4da6ff;
  --color-note-magenta: #d17aeb;
  --color-note-brown: #a57d4e;
  --quote-border-color: #98b8c2;
  --quote-background-color: #d8f0ff;
  --quote-text-color: #2a3a4a;
  --quote-symbol-color: #73939e;
  --quote-source-color: #5a7d8a;
  --daily-puzzle-active-color: #9a67d4;
}

body.jungle-theme {
  --underlay-color: #e0f2f1;
  --background-color: #c8e6c9;
  background-color: var(--underlay-color);
  --icon-color-filter: initial;
  --body-color: #1b5e20;
  color: var(--body-color);
  --hex-cell-enabled-inner-border-color: #66bb6a;
  --hex-cell-disabled-inner-border-color: #388e3c;
  --hex-disabled-color: #a5d6a7;
  --hex-color: #e8f5e9;
  --hex-bad-color: #d32f2f;
  --hex-good-color: #66bb6a;
  --hex-reset-color: #4caf50;
  --hex-hover-color: #a5d6a7;
  --button-background-color: var(--background-color);
  --button-hover-background-color: #81c784;
  --modal-background-color: #00000080;
  --info-box-background-color: #82c685;
  --input-background-color: var(--underlay-color);
  --input-border-color: #81c784;
  --color-note-blue: #42a5f5;
  --color-note-green: #66bb6a;
  --color-note-yellow: #ffee58;
  --color-note-orange: #ffb74d;
  --color-note-red: #ef5350;
  --color-note-purple: #ab47bc;
  --color-note-cyan: #26c6da;
  --color-note-magenta: #ec407a;
  --color-note-brown: #a1887f;
  --quote-border-color: #66bb6a;
  --quote-background-color: #e0f2f1;
  --quote-text-color: #1b5e20;
  --quote-symbol-color: #388e3c;
  --quote-source-color: #4caf50;
  --daily-puzzle-active-color: #ab47bc;
}

body.royal-theme {
  --underlay-color: #10061f;
  --background-color: #3c2b55;
  background-color: var(--underlay-color);
  --icon-color-filter: invert(100%);
  --body-color: #e0e0e0;
  color: var(--body-color);
  --hex-cell-enabled-inner-border-color: #462167;
  --hex-cell-disabled-inner-border-color: #2e0f48;
  --hex-disabled-color: #2e0f48;
  --hex-color: #6c57a3;
  --hex-bad-color: #cf6679;
  --hex-good-color: #81c784;
  --hex-reset-color: #5a4b89;
  --hex-hover-color: #8574b9;
  --button-background-color: var(--background-color);
  --button-hover-background-color: #6c57a3;
  --modal-background-color: #10061fcc;
  --info-box-background-color: #2e0f48;
  --input-background-color: var(--underlay-color);
  --input-border-color: #1e0b37;
  --color-note-blue: #738ada;
  --color-note-green: #a8c686;
  --color-note-yellow: #f7da66;
  --color-note-orange: #f79c66;
  --color-note-red: #f76d66;
  --color-note-purple: #a685d6;
  --color-note-cyan: #66c7d6;
  --color-note-magenta: #d666d6;
  --color-note-brown: #8b6c66;
  --quote-border-color: #462167;
  --quote-background-color: #2e0f48;
  --quote-text-color: #e0e0e0;
  --quote-symbol-color: #6c57a3;
  --quote-source-color: #8574b9;
  --daily-puzzle-active-color: #a685d6;
}

body.pumpkin-theme {
  --underlay-color: #2a1b0c;
  --background-color: #3e2612;
  --body-color: #e8d4b0;
  --icon-color-filter: invert(90%);
  --hex-cell-enabled-inner-border-color: #7a4e20;
  --hex-cell-disabled-inner-border-color: #5e3920;
  --hex-disabled-color: #2a1b0c;
  --hex-color: #d1851a;
  --hex-bad-color: tomato;
  --hex-good-color: #609477;
  --hex-reset-color: #d1851a;
  --hex-hover-color: #e89e48;
  --button-background-color: #3e2612;
  --button-hover-background-color: #e89e48;
  --info-box-background-color: #5e3920;
  --input-background-color: var(--underlay-color);
  --input-border-color: #7a4e20;
  --color-note-blue: #4169e1;
  --color-note-green: #3cb371;
  --color-note-yellow: #fc0;
  --color-note-orange: #ff8c00;
  --color-note-red: tomato;
  --color-note-purple: #9932cc;
  --color-note-cyan: #20b2aa;
  --color-note-magenta: #f0f;
  --color-note-brown: #8b4513;
  --quote-border-color: #7a4e20;
  --quote-background-color: #2a1b0c;
  --quote-text-color: #e8d4b0;
  --quote-symbol-color: #d1851a;
  --quote-source-color: #e89e48;
  --daily-puzzle-active-color: #9932cc;
}

body.festive-theme {
  --underlay-color: #0a1913;
  --background-color: #132821;
  --body-color: #f1f1f1;
  --icon-color-filter: invert(100%);
  --hex-cell-enabled-inner-border-color: #6e8f78;
  --hex-cell-disabled-inner-border-color: #3d5246;
  --hex-disabled-color: #25332d;
  --hex-color: #a9c9a6;
  --hex-bad-color: #c04f4f;
  --hex-good-color: #81b081;
  --hex-reset-color: #567e63;
  --hex-hover-color: #b22222;
  --button-background-color: #567e63;
  --button-hover-background-color: #b22222;
  --info-box-background-color: #1e3d33;
  --input-background-color: var(--underlay-color);
  --input-border-color: #c8b491;
  --color-note-blue: #a6d3eb;
  --color-note-green: #16a132;
  --color-note-yellow: #e8c44f;
  --color-note-orange: #d89a6e;
  --color-note-red: #bf5757;
  --color-note-purple: #a687ba;
  --color-note-cyan: #7ecfc3;
  --color-note-magenta: #d88fa5;
  --color-note-brown: #947151;
  --quote-border-color: #6e8f78;
  --quote-background-color: #132821;
  --quote-text-color: #f1f1f1;
  --quote-symbol-color: #b22222;
  --quote-source-color: #8cbf96;
  --daily-puzzle-active-color: #a687ba;
}

body.e-ink {
  --underlay-color: #ccc;
  --background-color: #fff;
  --body-color: #000;
  --icon-color-filter: invert(0%);
  --hex-cell-enabled-inner-border-color: #000;
  --hex-cell-disabled-inner-border-color: #bbb;
  --hex-disabled-color: #999;
  --hex-color: #eee;
  --hex-bad-color: red;
  --hex-good-color: #0f0;
  --hex-reset-color: #000;
  --hex-hover-color: #444;
  --button-background-color: transparent;
  --button-text-color: #000;
  --button-hover-background-color: #ddd;
  --button-hover-text-color: #000;
  --info-box-background-color: #fff;
  --info-box-border-color: #000;
  --input-background-color: #fff;
  --input-border-color: #ddd;
  --color-note-blue: #00f;
  --color-note-green: #0a0;
  --color-note-yellow: gold;
  --color-note-orange: #ff4500;
  --color-note-red: red;
  --color-note-purple: purple;
  --color-note-cyan: #0ff;
  --color-note-magenta: #f0f;
  --color-note-brown: #5a2d0c;
  --quote-border-color: #000;
  --quote-background-color: #fff;
  --quote-text-color: #000;
  --quote-symbol-color: #000;
  --quote-source-color: #555;
  --focus-outline-color: red;
  --daily-puzzle-active-color: #333;
}

a {
  color: var(--body-color);
}

input, select, textarea, button {
  border-radius: 4px;
  padding: 8px;
  font-family: inherit;
  font-size: .9em;
  color: var(--body-color) !important;
  background-color: var(--input-background-color) !important;
  border: 1px solid var(--input-border-color) !important;
}

select {
  cursor: pointer;
}

.content-container {
  background-color: var(--background-color);
  z-index: 0;
  border-radius: 0 20px 0 0;
  flex-grow: 1;
  width: 97%;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  left: 1.5%;
}

.main-content {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

h1 {
  font-size: 2.5em;
}

.left-content p, ul, li, pre, code {
  text-align: left;
}

.quote-container {
  background-color: var(--quote-background-color);
  border-left: 5px solid var(--quote-border-color);
  color: var(--quote-text-color);
  margin: 20px 0;
  padding: 20px;
  position: relative;
}

blockquote {
  margin: 0;
  padding: 0;
  font-size: 1.2em;
  font-style: italic;
  line-height: 1.4;
  position: relative;
}

blockquote:before {
  content: "“";
  color: var(--quote-symbol-color);
  font-size: 3em;
  line-height: 1;
  position: absolute;
  top: -10px;
  left: -10px;
}

blockquote:after {
  content: "”";
  color: var(--quote-symbol-color);
  font-size: 3em;
  line-height: 1;
  position: absolute;
  bottom: -20px;
  right: -10px;
}

cite {
  text-align: right;
  color: var(--quote-source-color);
  margin-top: 10px;
  font-style: normal;
  font-weight: bold;
  display: block;
}

hexakai-board {
  --color: var(--body-color);
  --font: var(--body-font);
  --color-note-1: var(--color-note-blue);
  --color-note-2: var(--color-note-green);
  --color-note-3: var(--color-note-yellow);
  --color-note-4: var(--color-note-orange);
  --color-note-5: var(--color-note-red);
  --color-note-6: var(--color-note-purple);
  --color-note-7: var(--color-note-cyan);
  --color-note-8: var(--color-note-magenta);
  --color-note-9: var(--color-note-brown);
}

@media screen and (width <= 1500px) {
  h1 {
    margin: 9px;
    font-size: 1.5em;
  }

  nav {
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: .9em;
  }
}

@media screen and (width <= 1300px) {
  .main-content {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
}

@media screen and (width <= 700px) {
  body {
    font-size: .9em;
  }

  .content-container {
    width: 100%;
    left: 0;
  }
}
/*# sourceMappingURL=app.4834a44d.css.map */
